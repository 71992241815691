var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_c('section',{staticClass:"bg-invoice bg-light"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-5 pt-4 pt-sm-0 justify-content-center"},[_c('div',{staticClass:"col-lg-10"},[_c('div',{staticClass:"card shadow rounded border-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"invoice-top pb-4 border-bottom"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_vm._m(0),_c('a',{staticClass:"text-primary h6",attrs:{"href":"javascript:void(0)"}},[_c('link-icon',{staticClass:"fea icon-sm text-muted mr-2"}),_vm._v("www.landrick.corp ")],1)]),_c('div',{staticClass:"col-md-4 mt-4 mt-sm-0"},[_c('h5',[_vm._v("Address :")]),_c('dl',{staticClass:"row mb-0"},[_c('dt',{staticClass:"col-2 text-muted"},[_c('map-pin-icon',{staticClass:"fea icon-sm"})],1),_vm._m(1),_c('dt',{staticClass:"col-2 text-muted"},[_c('mail-icon',{staticClass:"fea icon-sm"})],1),_vm._m(2),_c('dt',{staticClass:"col-2 text-muted"},[_c('phone-icon',{staticClass:"fea icon-sm"})],1),_vm._m(3)])])])]),_vm._m(4),_vm._m(5),_c('div',{staticClass:"invoice-footer border-top pt-4"},[_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"text-sm-right text-muted text-center"},[_c('h6',{staticClass:"mb-0"},[_c('router-link',{staticClass:"text-primary",attrs:{"to":"/page-terms","target":"_blank"}},[_vm._v("Terms & Conditions")])],1)])])])])])])])])])]),_c('Footer'),_c('Switcher'),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#topnav'),expression:"'#topnav'"}],staticClass:"btn btn-icon btn-primary back-to-top",attrs:{"href":"javascript: void(0);","id":"back-to-top"}},[_c('arrow-up-icon',{staticClass:"icons"})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-invoice mb-2"},[_vm._v("Landrick"),_c('span',{staticClass:"text-primary"},[_vm._v(".")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',{staticClass:"col-10 text-muted"},[_c('a',{staticClass:"video-play-icon text-muted",attrs:{"href":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"}},[_c('p',{staticClass:"mb-0"},[_vm._v("1419 Riverwood Drive,")]),_c('p',{staticClass:"mb-0"},[_vm._v("Redding, CA 96001")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',{staticClass:"col-10 text-muted"},[_c('a',{staticClass:"text-muted",attrs:{"href":"mailto:contact@example.com"}},[_vm._v("info@landrick.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',{staticClass:"col-10 text-muted"},[_c('a',{staticClass:"text-muted",attrs:{"href":"tel:+152534-468-854"}},[_vm._v("(+12) 1546-456-856")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"invoice-middle py-4"},[_c('h5',[_vm._v("Invoice Details :")]),_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col-md-8 order-2 order-md-1"},[_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-md-3 col-5 font-weight-normal"},[_vm._v("Invoice No. :")]),_c('dd',{staticClass:"col-md-9 col-7 text-muted"},[_vm._v("land45845621")]),_c('dt',{staticClass:"col-md-3 col-5 font-weight-normal"},[_vm._v("Name :")]),_c('dd',{staticClass:"col-md-9 col-7 text-muted"},[_vm._v("Calvin Carlo")]),_c('dt',{staticClass:"col-md-3 col-5 font-weight-normal"},[_vm._v("Address :")]),_c('dd',{staticClass:"col-md-9 col-7 text-muted"},[_c('p',{staticClass:"mb-0"},[_vm._v("1962 Pike Street,")]),_c('p',[_vm._v("Diego, CA 92123")])]),_c('dt',{staticClass:"col-md-3 col-5 font-weight-normal"},[_vm._v("Phone :")]),_c('dd',{staticClass:"col-md-9 col-7 text-muted"},[_vm._v("(+45) 4584-458-695")])])]),_c('div',{staticClass:"col-md-4 order-md-2 order-1 mt-2 mt-sm-0"},[_c('dl',{staticClass:"row mb-0"},[_c('dt',{staticClass:"col-md-4 col-5 font-weight-normal"},[_vm._v("Date :")]),_c('dd',{staticClass:"col-md-8 col-7 text-muted"},[_vm._v("15th Oct, 2019")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"invoice-table pb-4"},[_c('div',{staticClass:"table-responsive bg-white shadow rounded"},[_c('table',{staticClass:"table mb-0 table-center invoice-tb"},[_c('thead',{staticClass:"bg-light"},[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("No.")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Item")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Qty")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Rate")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Total")])])]),_c('tbody',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v("1")]),_c('td',{staticClass:"text-left"},[_vm._v("Inclusive Insights")]),_c('td',[_vm._v("2")]),_c('td',[_vm._v("$ 2600")]),_c('td',[_vm._v("$ 5200")])]),_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v("2")]),_c('td',{staticClass:"text-left"},[_vm._v("Handy Guide")]),_c('td',[_vm._v("1")]),_c('td',[_vm._v("$ 3660")]),_c('td',[_vm._v("$ 3660")])]),_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v("3")]),_c('td',{staticClass:"text-left"},[_vm._v("Premiere Product")]),_c('td',[_vm._v("3")]),_c('td',[_vm._v("$ 4580")]),_c('td',[_vm._v("$ 13740")])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-5 ml-auto"},[_c('ul',{staticClass:"list-unstyled h5 font-weight-normal mt-4 mb-0"},[_c('li',{staticClass:"text-muted d-flex justify-content-between"},[_vm._v("Subtotal :"),_c('span',[_vm._v("$ 22600")])]),_c('li',{staticClass:"text-muted d-flex justify-content-between"},[_vm._v("Taxes :"),_c('span',[_vm._v(" 0")])]),_c('li',{staticClass:"d-flex justify-content-between"},[_vm._v("Total :"),_c('span',[_vm._v("$ 22600")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"text-sm-left text-muted text-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Customer Services : "),_c('a',{staticClass:"text-warning",attrs:{"href":"tel:+152534-468-854"}},[_vm._v("(+12) 1546-456-856")])])])])
}]

export { render, staticRenderFns }